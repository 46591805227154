
import { computed, defineComponent, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { api } from '@/services/Api';
import { Document, DocumentsFilter } from '@/models/Document';
import useOrder from '@/modules/useOrder';
import swal from 'sweetalert2';
import Q from 'q';
import { DocumentType, InvoiceType } from '@/models/Enums';
import router from '@/router';
import { generalStore } from '@/store';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { IdName } from '@/models/Interfaces';

export default defineComponent({
    name: 'Documents',
    async setup() {
        onBeforeRouteLeave(async to => {
            if (to.name != 'filterdocuments' && to.name != 'invoiceView' && to.name != 'receiptView' && to.name != 'sharedocument') {
                generalStore.commit('saveDocumentsFilter', new DocumentsFilter());
            }
            return true;
        });
        const loaded = ref(false);
        const { t } = useI18n();
        const selectedDocument = ref<Document | null>(null);
        const filter = ref<DocumentsFilter>(new DocumentsFilter());
        const perfomaInvoiceTitle = computed(() => generalStore.state.defaultProviderSettings?.defaultProviderSettingsPerfomaInvoiceTitle);
        filter.value = generalStore.getters.getDocumentsFilter;
        if (filter.value) {
            filter.value.pageIndex = 0;
        }
        const total = ref(0);
        const totalFiltered = ref(0);
        const busy = ref(false);
        const hasNext = ref(false);
        const providerDocTypes = ref<IdName[]>([]);
        const emitter: any = inject('emitter');
        const { removemillis } = useOrder();
        const documents = ref<Document[]>([]);
        const hasFilter = computed<boolean>(() => {
            if (filter.value.customer) {
                return true;
            }
            if (filter.value.number) {
                return true;
            }
            if (filter.value.createDateStart) {
                return true;
            }
            if (filter.value.createDateStart) {
                return true;
            }
            if (filter.value.type) {
                return true;
            }
            if (filter.value.orderNumber) {
                return true;
            }
            return false;
        });
        const totalAmount = ref(0);
        const totalVat = ref(0);
        const totalAmountBeforeVat = ref(0);

        function fixFilter() {
            if (!filter.value.number) {
                filter.value.number = null;
            }
            if (!filter.value.type) {
                filter.value.type = null;
            }
            if (!filter.value.orderNumber) {
                filter.value.orderNumber = null;
            }
        }

        async function getDocuments(closeSwal = true) {
            swal.showLoading();
            busy.value = true;
            fixFilter();
            const filterReq: DocumentsFilter = JSON.parse(JSON.stringify(filter.value));
            if (filterReq.type) {
                if (isNaN(filterReq.type)) {
                    filterReq.providerDocumentTypeId = filterReq.type.toString();
                    filterReq.type = null;
                } else {
                    filterReq.providerDocumentTypeId = null;
                }
            }
            const apiResult = await api.GetDocuments(filterReq);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return false;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (apiResult.data && filter.value.pageIndex == 0) {
                total.value = apiResult.data.total;
                totalFiltered.value = apiResult.data.totalFiltered;
            }
            if (apiResult.data && documents.value && documents.value.length && apiResult.data.rows) {
                documents.value = [...documents.value, ...apiResult.data.rows];
            } else {
                documents.value = apiResult.data?.rows || [];
            }

            filter.value.pageIndex++;
            busy.value = false;
            totalAmount.value = apiResult.data?.totalAmount ?? 0;
            totalVat.value = apiResult.data?.totalVat ?? 0;
            totalAmountBeforeVat.value = apiResult.data?.totalAmountBeforeVat ?? 0;

            if (closeSwal) {
                swal.close();
            }
            return true;
        }

        async function ListenerGetDocuments() {
            if (hasNext.value && !busy.value) {
                await getDocuments();
            }
        }

        async function getDocumentsOnClick() {
            documents.value = [];
            filter.value.pageIndex = 0;
            getDocuments();
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;

            await getDocumentsOnClick();
        }

        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetDocuments);
        }

        const convertArrayToObject = (array: any, key: any, val: any) => {
            const initialValue = {};
            return array.reduce((obj: any, item: any) => {
                return {
                    ...obj,
                    [item[key]]: item[val]
                };
            }, initialValue);
        };

        async function getProviderDocTypes() {
            const apiResult = await api.GetProviderDocTypes();
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                providerDocTypes.value = convertArrayToObject(apiResult.data, 'id', 'name');
            }
        }

        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetDocuments);
            const result = await getDocuments(false);
            if (filter.value.orderId) {
                await getProviderDocTypes();
            }
            loaded.value = true;
            if (result) {
                swal.close();
            }
        };

        function goFilter() {
            generalStore.commit('saveDocumentsFilter', filter.value);
            router.push({ name: 'filterdocuments' });
        }

        async function showAll() {
            filter.value = new DocumentsFilter();
            documents.value = [];
            generalStore.commit('saveDocumentsFilter', filter.value);
            await getDocuments();
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        function showMobileActions(doc: Document) {
            selectedDocument.value = doc;
            const element = document.getElementById('mobile-documents-actions');
            if (element) {
                element.style.display = 'block';
            }
        }

        function closeMobileActions() {
            const element = document.getElementById('mobile-documents-actions');
            if (element) {
                element.style.display = 'none';
            }
        }

        async function getVoucherDocument(doc: Document) {
            swal.showLoading();
            const apiPromise = api.getVoucherDoc(doc.id);
            const response = await apiPromise;
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            if (!response.data) {
                return;
            }
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const splitedUrl = doc.pdfUrl.split('/');
            const filename = splitedUrl[splitedUrl.length - 1];
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            swal.close();
        }

        async function showPdf(doc: Document) {
            if (doc.documentType == DocumentType.Custom) {
                getVoucherDocument(doc);
                return;
            }

            if (doc.pdfUrl) {
                window.open(doc.pdfUrl, '_blank');
            } else {
                swal.showLoading();
                const response = await api.GetDocumentPdfUrl(doc.id);
                await Q.delay(400);
                swal.close();
                if (response.errorMessage) {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                } else if (response.data) {
                    if (response.data.pdfUrl) {
                        doc.pdfUrl = response.data.pdfUrl;
                        window.open(response.data.pdfUrl, '_blank');
                    } else {
                        swal.fire({
                            icon: 'warning',
                            title: t('alert.pfgNotReady'),
                            confirmButtonText: t('button.close')
                        });
                    }
                }
            }
        }

        async function shareDocument(doc: Document) {
            router.push({ name: 'sharedocument', params: { documentId: doc.id } });
        }

        const getDocumentType = (doc: Document) => {
            switch (doc.documentType) {
                case DocumentType.OrderSummary:
                    return t('accounting.orderSummary');
                case DocumentType.Receipt:
                    return t('accounting.receipt');
                case DocumentType.Invoice:
                    switch (doc.invoiceType) {
                        case InvoiceType.Credit:
                            return t('accounting.creditInvoice');
                        case InvoiceType.Perfoma:
                            return perfomaInvoiceTitle.value;
                    }
                    return t('accounting.invoice');
                case DocumentType.InvoiceReceipt:
                    return t('accounting.invoice-receipt');
                default:
                    if (doc.documentType) return DocumentType[doc.documentType];
            }
        };
        const isObjectEmpty = (objectName: object) => {
            return Object.keys(objectName).length === 0;
        };

        function callUploadingFunction(): void {
            if (isObjectEmpty(providerDocTypes.value)) {
                swal.fire({
                    icon: 'error',
                    text: 'Need create custom documents'
                });
                return;
            }
            document.getElementById('file-upload-input')?.click();
        }
        async function uploadingFileValidation(e: any): Promise<void> {
            let file = e.target.files[0];
            if (!file) {
                return;
            }
            if (filter.value.orderId == '') {
                swal.fire({
                    icon: 'error',
                    text: 'Missing order id'
                });
                file = null;
                return;
            }
            swal.showLoading();
            const fileExt = file.name.split('.').pop();
            const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf'];

            if (!fileTypes.includes(fileExt)) {
                swal.fire({
                    icon: 'error',
                    text: 'Error file type'
                });
                file = null;
                return;
            }

            if (file.size > 5242880) {
                swal.fire({
                    icon: 'error',
                    text: 'File size should be less or equal to 5 MB'
                });
                file = null;
                return;
            }
            const fd = new FormData();
            fd.append('file', file, file.name);

            const { value: type } = await swal.fire({
                title: t('alert.select-doc-type'),
                input: 'select',
                inputOptions: providerDocTypes.value,
                customClass: {
                    confirmButton: 'popup-bookit-button',
                    cancelButton: 'close-button-wrapper popup-bookit-button'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('label.upload'),
                cancelButtonText: t('button.cancel'),
                reverseButtons: true,
                inputValidator: value => {
                    return new Promise(resolve => {
                        if (!value) {
                            resolve('You need to select document type');
                        } else {
                            resolve('');
                        }
                    });
                }
            });
            if (!type) {
                file = null;
                e.target.value = '';
                return;
            }

            const uploadResponse = await api.uploadVoucherDocument(fd, filter.value.orderId, type);
            if (uploadResponse.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                file = null;
                e.target.value = '';
                return;
            }
            if (uploadResponse.data) {
                documents.value.unshift(uploadResponse.data.row);
            }

            swal.close();
            e.target.value = '';
        }

        function goToDetailsOfDoc(doc: Document) {
            switch (doc.documentType) {
                case DocumentType.Invoice: {
                    if (doc.businessClientId) {
                        router.push({ name: 'businessInvoiceView', params: { clientId: doc.businessClientId, invoiceId: doc.id } });
                    } else {
                        router.push({ name: 'invoiceView', params: { orderId: doc.orderId, invoiceId: doc.id } });
                    }
                    break;
                }
                case DocumentType.Receipt: {
                    if (doc.businessClientId) {
                        router.push({ name: 'businessReceiptView', params: { clientId: doc.businessClientId, receiptId: doc.id } });
                    } else {
                        router.push({ name: 'receiptView', params: { orderId: doc.orderId, receiptId: doc.id } });
                    }
                    break;
                }
                case DocumentType.InvoiceReceipt: {
                    router.push({ name: 'invoiceReceiptView', params: { orderId: doc.orderId, invoiceReceiptId: doc.id } });
                    break;
                }
                case DocumentType.Custom: {
                    getVoucherDocument(doc);
                    break;
                }
                case DocumentType.OrderSummary: {
                    window.open(doc.pdfUrl, '_blank');
                    break;
                }
            }
        }

        function getColorOfAmount(doc: Document) {
            if (doc.documentType === DocumentType.Invoice && doc.invoiceType === InvoiceType.Credit) {
                return doc.total >= 0 ? 'red' : 'green';
            }

            return doc.total >= 0 ? 'green' : 'red';
        }

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);
        return {
            documents,
            filter,
            getDocumentsOnClick,
            hasFilter,
            totalFiltered,
            total,
            DocumentType,
            getDocumentType,
            sotrBy,
            goFilter,
            removemillis,
            showAll,
            getArrowClass,
            showMobileActions,
            closeMobileActions,
            showPdf,
            selectedDocument,
            shareDocument,
            moment,
            callUploadingFunction,
            uploadingFileValidation,
            providerDocTypes,
            loaded,
            goToDetailsOfDoc,
            getColorOfAmount,
            totalAmount,
            totalVat,
            totalAmountBeforeVat
        };
    }
});
